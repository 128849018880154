<template>
  <div class="content-wrapper container-xxl p-0">
    <div class="content-header row"></div>
    <div class="content-body">
      <p class="mb-2">
        A role gives users access to specified menus and functions, allowing administrators to access the features they require based on the role they have been allocated.

      </p>

      <!-- Role cards -->
      <div class="d-flex justify-content-center">
        <b-spinner
          variant="success"
          label="Spinning"
          v-if="loading"
          style="width: 3rem; height: 3rem"
        ></b-spinner>
      </div>

      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="card">
            <div class="row">
              <div class="col-sm-12">
                <div class="card-body text-sm-end text-center ps-sm-0">
                  <a
                    href="javascript:void(0)"
                    v-b-modal.modal-add
                    class="stretched-link text-nowrap add-new-role"
                  >
                    <span class="btn btn-primary mb-3">Add New Role</span>
                  </a>
                  <p class="mb-0">Add role, if it does not exist</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6" v-for="item in role" :key="item">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-end mt-1 pt-25">
                <div class="role-heading">
                  <h4 class="fw-bolder">{{ item.name }}</h4>
                  <p class="fw-bolder">{{ item.description }}</p>
                  <a
                    href="javascript:;"
                    class="role-edit-modal"
                    v-b-modal.modal-edit
                    @click="sendInfo(item)"
                  >
                    <small class="fw-bolder">Edit Role</small>
                  </a>
                </div>
                <a href="javascript:void(0);" class="text-body"
                  ><i data-feather="copy" class="font-medium-5"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Role Modal -->
    <b-modal
      id="modal-add"
      ok-only
      @hidden="resetModal"
      ok-title="Accept"
      centered
      size="lg"
      title="Add new role"
      hide-footer="true"
    >
      <div class="modal-body px-5 pb-5">
        <div class="text-center mb-4">
          <h1 class="role-title">Add New Role</h1>
       
        </div>
        <!-- Add role form -->
        <form id="addRoleForm" class="row" @submit.prevent="addRole()">
          <div class="col-12">
            <label class="form-label" for="modalRoleName">Role Name</label>
            <input
              type="text"
              id="modalRoleName"
              name="modalRoleName"
              class="form-control"
              v-model="AddName"
              required
              placeholder="Enter role name"
            />
          </div>
          <div class="col-12 my-1">
            <label class="form-label" for="modalRoleName">Description</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Description"
              rows="3"
              required
              v-model="AddDescription"
            />
          </div>

          <div class="col-12 text-center mt-2 d-flex justify-content-between">
            <b-button type="submit" class="btn btn-primary me-1">
              <b-spinner small v-if="loading" />Add Role</b-button
            >
          </div>
        </form>
        <!--/ Add role form -->
      </div>
    </b-modal>

    <!-- Edit Role Modal -->
    <b-modal
      id="modal-edit"
      ok-only
      @hidden="handleModalClose"
      ok-title="Accept"
      centered
      size="lg"
      title="Edit role"
      hide-footer="true"
    >
      <div class="modal-body px-5 pb-5">
        <div class="text-center mb-4">
          <h1 class="role-title">Edit this role</h1>
          <p>Edit / Set role permissions</p>
        </div>

        <!-- Add role form -->
        <form id="editRoleForm" class="row" @submit.prevent="updateRole()">
          <div class="col-12 my-1">
            <label class="form-label" for="modalRoleName">Role Name</label>
            <input
              type="text"
              id="modalRoleName"
              name="modalRoleName"
              class="form-control"
              placeholder="Enter role name"
              v-model="selectedRole.name"
              required
            />
          </div>
          <div class="col-12 my-1">
            <label class="form-label" for="modalRoleName">Description</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Description"
              rows="3"
              required
              v-model="selectedRole.description"
            />
          </div>
          <div class="col-12 my-1">
            <b-button variant="danger" @click="removeRole(selectedRole.id)">
              <b-spinner small v-if="loading" />Delete Role
            </b-button>
          </div>
          <div class="col-12">
            <h4 class="mt-2 pt-50">Role Permissions</h4>
            <!-- Permission table -->
            <div class="table-responsive">
              <table class="table table-flush-spacing">
                <tbody>
                  <tr>
                    <td class="text-nowrap fw-bolder">
                      Administrator Access
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Allows a full access to the system"
                      >
                        <i data-feather="info"></i>
                      </span>
                    </td>
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="selectAll"
                          v-model="selectAllData"
                          @change="selectAllFunction(selectAllData)"
                        />
                        <label class="form-check-label" for="selectAll">
                          Select All
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="permission in permissionsPersonal" :key="permission.value">
                    <td class="text-nowrap fw-bolder">{{ permission }}</td>
                    <td>
                      <div class="d-flex">
                        <div class="form-check me-3 me-lg-5">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="permission"
                            v-model="selectedPermissions"
                            :id="permission"
                          />
                          <label class="form-check-label" :for="permission">
                            Grant
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Permission table -->
          </div>

          <div class="col-12 text-center mt-2 d-flex justify-content-between">
            <b-button type="submit" class="btn btn-primary me-1">
              <b-spinner small v-if="loading" />Edit Role</b-button
            >
          </div>
        </form>
        <!--/ Add role form -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BSpinner, BFormTextarea, BButton } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import api from "@/api/api-v1-config";

export default {
  name: "Role Management",
  components: {
    BSpinner,
    BFormTextarea,
    BButton,
  },

  data() {
    return {
      ///edit'
      permissionsPersonal: [],
      EditDescription: "",
      EditRoleName: "",
      EditPermissions: [],
      selectAllData: false,
      ///Add
      AddDescription: "",
      AddPermissions: [],

      roles: [],
      AddName: "",
      token: localStorage.getItem("accessToken"),
      showSpinner: true,
      selectedRole: [],
      selectedPermissions: [],

      token: localStorage.getItem("accessToken"),
    };
  },
  computed: {
    ...mapGetters("roleModule", {
      role: "role",
      loading: "loading",
    }),
  },

  methods: {
    async getPermissions() {
      let conf = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      };
      await api
        .get("/personal/permissions", conf)

        .then((response) => {
          this.permissionsPersonal = response.data.result;

          this.showSpinner = false;
        })
        .catch((error) => {
          console.log(error);
          this.showSpinner = false;
        });
    },
    success() {
      this.$swal({
        title: "Role Added!",
        text: "You have successfully added a Role!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    // error
    error(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data);
      this.$swal({
        title: "Error!",
        //text: msg ?? ' Failed!',
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(data) {
      let msg = null;
      if (data.errors) {
        let errors = data.errors;
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
      } else if (data.message) {
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        msg += data.message;
        msg += "</div>";
      }
      return msg;
    },
    successUpdate() {
      this.$swal({
        title: "Role Updated!",
        text: "You have successfully updated a Role!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    resetModal() {
      this.AddName = "";
      this.AddDescription = "";
    },
    errorUpdate(data) {
      this.$swal({
        title: "Error!",
        text: data,
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(errors) {
      let msg = null;
      if (errors) {
        msg = "<div>";
        msg = "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
        return msg;
      }
    },
    ...mapActions("roleModule", [
      "getRoleListAction",
      "removeRoleAction",

      "addRoleAction",
    ]),
    sendInfo(info) {
      this.selectedRole = info;
      this.permissionsPersonal.forEach((permission) => {
        if (info.permissions.includes(permission)) {
          this.selectedPermissions.push(permission);
        }
      });
    },

    updateRole() {
      let payload = {
        roleId: this.selectedRole.id,
        permissionId: this.selectedPermissions,
      };

      api.defaults.headers.Authorization = "Bearer " + this.token;
      api
        .put("roles/" + this.selectedRole.id + "/permissions", payload)
        .then(() => {
          this.getRoleListAction();
          this.$bvModal.hide("modal-edit");
          successUpdate();
        })
        .catch((error) => {
          console.log(error.response);
          errorUpdate(error.response.data.message);
        });
    },

    selectAllFunction(item) {
      if (item) {
        this.permissionsPersonal.forEach((role) => {
          this.selectedPermissions.push(role);
        });
      }
      if (!item) {
        this.permissionsPersonal.forEach(() => {
          this.selectedPermissions = [];
        });
      }
    },
    removeRole(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeRoleAction(id)
            .then((response) => {
              this.getRoleListAction();
              console.log(response);
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Role has been deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              this.error(error.response.data);

              this.$bvModal.hide("modal-edit");
              this.getRoleListAction();
            });

          this.$bvModal.hide("modal-edit");
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Role not deleted:)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.$bvModal.hide("modal-edit");
        }
      });
    },
    addRole() {
      let payload = {
        name: this.AddName,
        description: this.AddDescription,
      };
      this.addRoleAction(payload)
        .then((response) => {
          this.success();
          this.$bvModal.hide("modal-add");
          this.getRoleListAction();
          this.AddName = "";
          this.AddDescription = "";
        })
        .catch((error) => {
          this.error(error.response.data.errors);
          console.log(response);
          this.$bvModal.hide("modal-add");
          this.getRoleListAction();
          this.AddName = "";
          this.AddDescription = "";
        });
    },
    handleModalClose() {
      this.selectedPermissions.splice(0, this.selectedPermissions.length);
    },
  },

  async mounted() {
    this.getPermissions();
    await this.getRoleListAction()
      .then((response) => {
        console.log(response);
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
